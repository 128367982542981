import React from 'react'; // Make sure to import React
import me from "../../assets/images/face.png";
import GmailImg from '../../assets/images/gmailnew.png';
import LinkedinImg from '../../assets/images/linkedinnew.png';
import SketchFabImg from '../../assets/images/sketchfab.png';
import GithubImg from '../../assets/images/githubnew.png';
import PlayImg from '../../assets/images/playstorenew.png';
import styles from './About.module.css'
// import { View, Animated, Easing, StyleSheet, Image } from 'react-native';
// import { TouchableOpacity } from 'react-native-gesture-handler';

const Aboutme = React.forwardRef((props, ref) => (

  <section className={styles.section} ref={ref}>
    <div className={styles.topBlur}></div>
    <div className={styles.bottomBlur}></div>
   

      <div className={styles.aboutHalf}>
        <div className={styles.hi}>
          <div>Hi, i'm Kewal !</div>  <span className={styles.wavingHand}>👋🏽</span> </div>
        <div>
        <div className={styles.typewriter}>
            <h1>  <b>Software Engineer</b> | <b>Master's CS Student |</b> ...</h1>
          </div>
          <p className={styles.summary}>
          Your guy for building creative and scalable Full-Stack solutions.<br />
          </p>
          <ul className={styles.summarypoints}>
            <li>Mobile/Web Applications</li> 
            <li>XR experiences</li>
            <li>Distributed Systems</li>
          </ul>
        </div>
      </div>

      <div className={styles.half}>
        <div>
          <img
            alt="me"
            src={me}
            className={styles.profilePic}
          />
        </div>
        <div style={{ display: 'fixed', right: '0', top: '0' }} >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a target="_blank" rel="noopener noreferrer" href='mailto:kewalkishang@gmail.com'><img alt='gmail' className={styles.socialImg} src={GmailImg} /></a>
            <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/kewalkishang/'><img alt='social' className={styles.socialImg} src={LinkedinImg} /></a>
            <a target="_blank" rel="noopener noreferrer" href='https://sketchfab.com/kewalkishang'><img alt='sketch' className={styles.socialImg} src={SketchFabImg} /></a>
            <a target="_blank" rel="noopener noreferrer" href='https://github.com/kewalkishang'><img alt='github' className={styles.socialImg} src={GithubImg} /></a>
            <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/developer?id=OnlyKK'><img alt='social' className={styles.socialImg} src={PlayImg} /></a>
          </div>
        </div>
      </div>
 


  </section>
));

export default Aboutme;
