import React, { useRef } from 'react';
// import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav'
import Aboutme from './components/About/Aboutme';
import Education from './components/Education/Educationnew';
import Experience from './components/Experience/Experience';
import Skills from './components/Skills/Skills';

import Posts from './components/Posts/Posts';
import Projects from './components/Projects/Projects';
// import Socials from './components/Socials';
import Footer from './components/Footer';
import Avatar from './components/Avatar/Avatar';

function App() {
  const aboutMeRef = useRef(null);
  const educationRef = useRef(null);
  const experienceRef = useRef(null);
  const skillsRef = useRef(null);
  const postsRef = useRef(null);
  const projectsRef = useRef(null);
  // const socialsRef = useRef(null);
  
  const scrollToSection = (sectionRef) => {
    //console.log(" CALLED ", sectionRef )
    if (sectionRef.current) {
        //sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        const sectionOffsetTop = sectionRef.current.offsetTop;
        window.scrollTo({
          top: sectionOffsetTop - 0,
          behavior: 'smooth'
      });
      
    }
  };


  return (
    <div className="App">
            <Nav scrollToSection={scrollToSection} refs={{ aboutMe: aboutMeRef, education: educationRef, experience : experienceRef, skills: skillsRef, posts : postsRef, projects : projectsRef}}></Nav>

      <Aboutme ref={aboutMeRef} id="AboutmeSection"></Aboutme>
      <Education ref={educationRef} id="EducationSection"></Education>
      <Experience ref={experienceRef} id="ExperienceSection"></Experience>
      <Skills ref={skillsRef} id="SkillSection"></Skills>
      <Projects ref={projectsRef} id="ProjectSection"></Projects>
      <Posts ref={postsRef} id="PostSection"></Posts>
      <Avatar refs={{ aboutMe: aboutMeRef, education: educationRef, experience : experienceRef, skills: skillsRef, posts : postsRef, projects : projectsRef}}></Avatar>
     
      {/* <Socials ref={socialsRef}id="SocialSection"></Socials> */}

      <Footer></Footer>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
