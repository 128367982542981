function Footer() {
    return (
        <footer className="navbar navbar-inverse" style={{marginBottom : "0px"}}>
            <div className="container-fluid">
                {/* <div class="navbar-header" style={{textAlign: "center"}}> */}
                <div style={{textAlign : "center", fontSize: "20px", color: "white", marginTop : "5px"}}>
                    © 2024 Kewal Kishan Gokuldas
                    </div>
                {/* </div> */}
                {/* <div class="navbar-collapse" id="myFooter">
                    <ul class="nav navbar-nav navbar-right">
                        <li><a href="#">Terms of Service</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div> */}
            </div>
        </footer>
    );
}

export default Footer;