// import GotoImg from '../../assets/images/goto.png';
import styles from "./Posts.module.css";

function PostItem({ post })
{

    return(
        <div className={styles.projectitem}>
            <div className={styles.postOuter}>
     
            <img alt="uni" className={styles.projectImg} src={post.img}></img>
            <div style={{ color:"white", textAlign : "center" , display : 'block'}}>
                <div style={{fontSize:"18px", fontWeight : 500, padding : "5px", background : "rgba(0, 0, 0, 0.2)", width : "100%"}}> {post.title} </div>
            </div>

            
             <div style={{display:"flex", flexDirection:"row", justifyContent: "space-evenly", marginTop : "5px", textAlign : "center", alignItems : "center" }}>
                {post.topics.map((topic) => (
                    <div key={topic} className={styles.skill}> {topic} </div>
               ))}
             </div>
             <div className={styles.redirect}>
            { post.url &&  <a rel="noreferrer noopener" target="_blank" href={post.url} className={styles.redirectBtn}>Post</a> }
          
         </div>
             </div>
        </div>
    );
}

export default PostItem;