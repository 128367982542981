// import GotoImg from '../../assets/images/goto.png';
import styles from "./Projects.module.css";

function ProjectItem({project})
{
    return(
        <div className={styles.projectitem}>
            <img alt="uni" className={styles.projectImg} src={project.img}></img>
        <div className={styles.projectdetails}>
        <div style={{ fontSize:"15px", fontWeight : "bold"}}> {project.title} </div>
        <div  >
            {project.desc}
        </div>
   
         <div className="project-topic" style={{display:"flex", flexDirection:"row", justifyContent: "space-evenly", marginTop : "5px",     alignItems: 'center' }}>
                {project.topics.map((topic) => (
                    <div key={topic} className={styles.skill}> {topic} </div>
               ))}
         </div>
         <div className={styles.redirect}>
            { project.url &&  <a rel="noreferrer noopener" target="_blank" href={project.url} className={styles.redirectBtn}>Github</a> }
            { project.demo && <a rel="noreferrer noopener" target="_blank" href={project.demo} className={styles.redirectBtn}>Demo</a> }
            { project.app && <a rel="noreferrer noopener" target="_blank" href={project.app} className={styles.redirectBtn}>App</a> }
         </div>
         </div>
    </div>
    );
}

export default ProjectItem;