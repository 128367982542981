import React from 'react';
import skillsImg from '../../assets/images/education.png';
import styles from './Education.module.css'
import northImg from '../../assets/images/northeastern.png';
import vtuImg from '../../assets/images/vtu.png';

const Education = React.forwardRef((props, ref) => (

  <div className={styles.section} ref={ref}>
    <div className={styles.topBlur}></div>
    {/* <div className={styles.bottomBlur}></div> */}
    <div>
      <div style={{ fontWeight: "bold", fontSize: "32px", color: "white" }}><img src={skillsImg} alt="C++" style={{ height: "25px", margin: "10px" }} />Education</div>
    </div>
    <div className={styles.educationList}>
     
      <div className={styles.educationitem}>
      <div className={styles.educationimage}>
        <img alt="uni" className={styles.uniImg} src={northImg} />
      </div>
        <div className={styles.educationdetails}>
          <div className={styles.educationUni}> Northeastern University</div>
            <div className={styles.educationCollege} >Khoury College of Computer Science</div>
            <div className={styles.educationyear} >Sept 2022 - Dec 2024</div> 
            <hr></hr>     
            <div className={styles.educationprogram}>Master's in Computer Science</div>
            <div className={styles.educationgpa}> GPA : 3.9</div>
        </div>
        <div className={styles.desc}>
          <div>
            <ul>
              <li>
                Founder and President of NuXR
              </li>
              <li>
                Graduate Teaching Assistant <br /> - Mobile Application Development <br></br> - Align Math
              </li>
              <li>
              Related Courses: Algorithms, Distributed Systems,<br></br> 
              DBMS, Pattern Recognition and Computer Vision
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.educationitem}>
      <div className={styles.educationimage}>
        <img alt="uni" className={styles.uniImg} src={vtuImg} />
      </div>
        <div className={styles.educationdetails}>
          <div className={styles.educationUni}> Visvesvaraya Technological University </div>
            <div className={styles.educationCollege} >Dayananda Sagar College of Engineering</div>
            <div className={styles.educationyear} >Aug 2013 - May 2017</div> 
            <hr></hr>     
            <div className={styles.educationprogram}>Bachelor's in Computer Science</div>
           
        </div>
        <div className={styles.desc}>
          <div>
            <ul>
              <li>
              Mentor for Microsoft Innovation lab
              </li>
              <li> 
              Related Courses : Object Oriented Design, <br></br>
               Algorithms, Web Development,  <br></br>
               DBMS, Micro-processor, OS
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
   
  </div>
));

export default Education;