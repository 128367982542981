import React from "react";
import Slider from "react-slick";

import "./Slideshow.css"; 
import styles from "./Projects.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectItem from "./ProjectItem";

import resumeImg from '../../assets/images/resume.png';
import clockImg from '../../assets/images/clock.png';
import furnitureImg from '../../assets/images/furniture.png';
import umlImg from '../../assets/images/uml.png';
import downloaderImg from '../../assets/images/downloader.png';
import skillsImg from '../../assets/images/idea.png';
import fabImg from '../../assets/images/fab.png';
import nuxrImg from '../../assets/images/nuxr.png';
import classImg from '../../assets/images/class.jpg';
import locationImg from '../../assets/images/location.jpg';
import mobileImg from '../../assets/images/mobile.jpg';
import cryptoImg from '../../assets/images/crypto.jpg';
import geoImg from '../../assets/images/geo.jpg';
import argoImg from '../../assets/images/argo.png';
import vrImg from '../../assets/images/vr.png';
import cookImg from '../../assets/images/cook.png';
import alnumImg from '../../assets/images/alnum.png';
import swiftswapImg from '../../assets/images/swiftswap.png';

import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
// import type { ReactTabsFunctionComponent, TabProps } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const Projects = React.forwardRef((props, ref) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
  };

  const appprojects= [
    {id:1, title: 'NuXR', url: 'https://github.com/NuXR-NEU/NuXR-Website', topics : [ 'React', 'OpenGL', 'JavaScript' ], desc : "The official website for the XR club at Northeastern University.", img : nuxrImg, demo : "https://www.nuxrneu.com" },
    {id:2, title: 'Wander Work', topics : [ 'React Native', 'Firebase','Maps API'], desc : "Cross Platform mobile app to find, add and review workplaces." , img : mobileImg, demo : 'https://www.youtube.com/watch?v=34r7FiyhBpE' },
    {id:3, title: 'Swift Swap', app: 'https://play.google.com/store/apps/details?id=com.onlykk.swiftswap' , topics : [ 'Android', 'Bluetooth', 'RoomDB' ], desc : "Android App that enables users to save and query, shared contact information from other users, using Bluetooth" , img : swiftswapImg, demo : 'https://www.youtube.com/watch?v=k5OFySjby-U&t=9s'  },
    {id:3, title: 'Auto-Silento', url: 'https://github.com/kewalkishang/AUTO-SILENTO--DSCE-' , topics : [ 'Android', 'Geofencing' ], desc : "Android app that auto switches ringer mode depending on user's lat/long values" , img : locationImg },
  ];

  const xrprojects= [
    {id:1, title: 'Aframe Clock Component', url: 'https://github.com/kewalkishang/aframe-clock-component', topics : [ 'Aframe', 'OpenGL', 'JavaScript' ], desc : "Aframe component for a 3D clock to be used in a VR scene for a web based immersive experience.", img : clockImg },
    {id:4, title: 'Metaverse Resume', url: 'https://github.com/kewalkishang/kewalkishang.github.io', topics : [ 'ModelViewer', 'OpenGL', 'JavaScript' ], desc : "A concept resume experience that is inspired from Metaverse avatars", img : resumeImg, demo : 'https://kewalkishang.github.io'  },
    {id:5, title: 'AR furniture Store', url: 'https://github.com/kewalkishang/AR-FurnitureStore' , topics : [ 'Android', 'Unity', 'ARCore' ], desc : "Experience retail in Augmented Reality. Place and manipulte 3D furniture in real space.", img : furnitureImg },
    {id:5, title: 'Geometry3D', url: 'https://github.com/kewalkishang/MathAR' , topics : [ 'Android', 'Unity', 'ARCore' ], desc : "Learn the different Geometry shapes using 3D/AR.", img : geoImg },
    {id:5, title: 'ARGo',  topics : [ 'Android', 'Unity', 'ARCore' ], desc : "Rapid prototyping application for 3D modeling using augmented reality. ", img : argoImg, demo : 'https://play.google.com/store/apps/details?id=com.OnlyKK.ARgo&hl=en_US&gl=US' },
    {id:5, title: 'VR Game Suite', url: 'https://github.com/kewalkishang/MathAR' , topics : [ 'Android', 'Unity', 'ARCore' ], desc : "Welcome to the vr game suite , containing 3 games for your entertainment. ", img : vrImg , demo : 'https://play.google.com/store/apps/details?id=com.OnlyKK.BALLBALANCE&hl=en_US&gl=US'},

  ];

  const aiprojects= [
    {id:1, title: 'AlNum AI', url: 'https://github.com/kewalkishang/AlNum-AI', topics : [ 'Tensorflow', 'React', 'JS' ], desc : "Your AI Companion for Character Writing Mastery", img : alnumImg, demo : 'https://alnumai.netlify.app' },
    {id:2, title: 'CookMate', url: 'https://github.com/kewalkishang/CookMate', topics : [ 'Tensorflow', 'React', 'JS'], desc : "CookMate is an innovative recipe suggestion application that integrates image classification into the culinary realm." , img : cookImg ,  demo : 'https://gilded-croissant-bfb411.netlify.app'},
    {id:1, title: 'Behavior Classifier', topics : [ 'Tensorflow', 'Python' ], desc : "Developed and executed an ml model utilizing TensorFlow’s transfer learning to recognize and differentiate human activities", img : classImg },
  ];

  const toolprojects= [
    {id:1, title: 'Unity UML Generator ', url: 'https://github.com/kewalkishang/Unity-UML-Generator', topics : [ 'C#', 'Unity'], desc : "Tool to generate UML diagram for Unity/C# projects. Uses Reflection API to inspect the classes, fields, methods, and constructors." , img : umlImg },
    {id:2, title: 'ImageNet Downloader', url: 'https://github.com/kewalkishang/ImageNet-Downloader' , topics : [ 'Python', 'tkinter', 'Tool' ], desc : "A python GUI based webscrapper to download images from http://image-net.org/ (ImageNet)" , img : downloaderImg },
    {id:1, title: 'CrytpoWatch', url: 'https://github.com/kewalkishang/CrytpoWatch', topics : [ 'C#', 'Unity'], desc : " The application lets you to select the choose the cypto that you want to keep track." , img : cryptoImg },
    {id:2, title: 'FAB : Unity', url: 'https://github.com/kewalkishang/Floating-Action-Button---Unity-' , topics : [ 'C#', 'Unity'], desc : "A ready to use FAB for Unity that facilitates easy navigation." , img : fabImg },
  ];


  return (
    <div className={styles.projectContainer} ref={ref}>
    <div className="main-title" style={{paddingTop: "25px", fontWeight : "bold", fontSize : "32px"}}>
    <img src={skillsImg} alt="C++" style={{height: "25px", margin : "10px"}} />
        Projects
    </div>
    <div>
    <Tabs className={styles.tabStyle}>
    <TabList className={styles.tabSection}>
      <Tab>App</Tab>
      <Tab>XR</Tab>
      <Tab>Tool</Tab>
      <Tab>AI</Tab>
    </TabList>

    <TabPanel className={styles.tabContent}>
    <div className="slideshow-container">
      <Slider {...settings}>
          {appprojects.map((project) => (
                <div className={styles.slide} key={project.id}>
                    <ProjectItem key={project.id} project={project}></ProjectItem>
                </div>
               ))}
      </Slider>
    </div> 
    </TabPanel>
    <TabPanel  className={styles.tabContent}>
    <div className="slideshow-container">
      <Slider {...settings}>
          {xrprojects.map((project) => (
                <div className={styles.slide} key={project.id}>
                    <ProjectItem key={project.id} project={project}></ProjectItem>
                </div>
               ))}
      </Slider>
    </div> 
    </TabPanel>
    <TabPanel  className={styles.tabContent}>
    <div className="slideshow-container">
      <Slider {...settings}>
          {toolprojects.map((project) => (
                <div className={styles.slide} key={project.id}>
                    <ProjectItem key={project.id} project={project}></ProjectItem>
                </div>
               ))}
      </Slider>
    </div> 
    </TabPanel>
    <TabPanel className={styles.tabContent}>
    <div className="slideshow-container">
      <Slider {...settings}>
          {aiprojects.map((project) => (
                <div className={styles.slide} key={project.id}>
                    <ProjectItem key={project.id} project={project}></ProjectItem>
                </div>
               ))}
      </Slider>
    </div> 
    </TabPanel>
   
  </Tabs>
    </div>
    </div>
  );
});

export default Projects;
