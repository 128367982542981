import React, {useState, useEffect} from 'react';
import 'aframe';  // Import A-Frame
import 'aframe-extras';
//import AFRAME from 'aframe';
window.AFRAME.registerComponent('drag-rotate-component', {
    schema: {
      speed: {default: 1}
    },
    init: function () {
      this.ifMouseDown = false;
      this.x_cord = 0;
      this.y_cord = 0;
      this.sceneEl = this.el.sceneEl; // Get the scene element

      this.onMouseDown = this.onDocumentMouseDown.bind(this);
      this.onMouseUp = this.onDocumentMouseUp.bind(this);
      this.onMouseMove = this.onDocumentMouseMove.bind(this);

      // Attach events to the scene
      this.sceneEl.addEventListener('mousedown', this.onMouseDown);
      this.sceneEl.addEventListener('mouseup', this.onMouseUp);
      this.sceneEl.addEventListener('mousemove', this.onMouseMove);
    },
    remove: function () {
      // Detach event listeners from the scene
      this.sceneEl.removeEventListener('mousedown', this.onMouseDown);
      this.sceneEl.removeEventListener('mouseup', this.onMouseUp);
      this.sceneEl.removeEventListener('mousemove', this.onMouseMove);
    },
    onDocumentMouseDown: function (event) {
      // Check if the mouse is over this entity
      //const intersected = this.checkIntersection(event);
      //if (intersected) {
        this.ifMouseDown = true;
        this.x_cord = event.clientX;
        this.y_cord = event.clientY;
      //}
    },
    onDocumentMouseUp: function () {
      this.ifMouseDown = false;
    },
    onDocumentMouseMove: function (event) {
      if (this.ifMouseDown) {
        this.rotateObject(event.clientX, event.clientY);
      }
    },
    rotateObject: function (x, y) {
      var temp_x = x - this.x_cord;
      var temp_y = y - this.y_cord;
      if (Math.abs(temp_y) < Math.abs(temp_x)) {
        this.el.object3D.rotation.y += temp_x * 0.005 * this.data.speed;
      } else {
        this.el.object3D.rotation.x += temp_y * 0.005 * this.data.speed;
      }
      this.x_cord = x;
      this.y_cord = y;
    },
    // checkIntersection: function (event) {
    //   // Perform raycasting or use the event's target to check if the interaction is with this entity
    //   let intersected = false;
    //   const intersects = this.sceneEl.components.raycaster.intersectedEls;
    //   if (intersects.includes(this.el) || event.target === this.el) {
    //     intersected = true;
    //   }
    //   return intersected;
    // }
});


  const animationMap = {
    aboutMe: 'wavehi', 
    education: 'throwup', 
    experience : 'carry', 
    skills: 'skills', 
    posts : 'thumbsup', 
    projects : 'projects'
};


const Avatar = ({refs}) => {
 
    const [animationName, setAnimationName] = useState('idle');

    useEffect(() => {
        const handleScroll = () => {
            const offset = 0;
            for (const sectionName in refs) {
                const ref = refs[sectionName];
                if (ref && ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    if (rect.top - offset <= 0 && rect.bottom >= 0 - offset) { 
                        setAnimationName(animationMap[sectionName]);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs]);
  
    return (
        <a-scene  xr-mode-ui="enabled: false" embedded style={{ width: '150px', height: '150px', position: 'fixed', bottom: '5px', right: '5px', zIndex : 100}}>
            {/* <a-assets>
            <a-asset-item id="my-model" src="/resumemodel.glb"></a-asset-item>
        </a-assets> */}
        <a-camera position="0 1.6 2" look-controls-enabled="false"></a-camera>

           <a-entity animation-mixer={`clip: ${animationName}; loop: repeat`}  drag-rotate-component="speed: 1" gltf-model="/resumemodel.glb" position="0 0 -3" scale="3 3 3"></a-entity>
        </a-scene>
    );
};

export default Avatar;