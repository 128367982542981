import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostItem from "./PostItem";
import styles from "./Posts.module.css"

import arvaImg from '../../assets/images/arva.png';
import aframeImg from '../../assets/images/aframe.png';
import portalImg from '../../assets/images/portal.png';
import skillsImg from '../../assets/images/blog.png';
import transferImg from '../../assets/images/transferPost.jpg';
import multiPaxosImg from '../../assets/images/multiNew.jpg';
import cnnImg from '../../assets/images/cnn.png';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Posts = React.forwardRef((props, ref) =>
{
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
      };

      const posts = [
        {id:1, title: 'Multi-Paxos : A more Practical Cousin of Paxos', url: 'https://medium.com/@kewalkishan/multi-paxos-a-more-practical-cousin-of-paxos-4b2a64be5cad', topics : ['Distributed Systems', 'Consensus' ], img : multiPaxosImg },
        {id:2, title: 'Transferring large images using Bluetooth', url: 'https://medium.com/@kewalkishan/transferring-large-images-using-bluetooth-android-e06356d67a17', topics : ['Android', 'Bluetooth', 'Sockets' ], img : transferImg },
        {id:3, title: 'My CNN Often Gets Confused Between ‘0’ and ‘O’', url: 'https://medium.com/@kewalkishan/my-cnn-often-gets-confused-between-0-and-o-ee61613eb289', topics : ['CNN', 'Computer Vision', 'Tf.js' ], img :  cnnImg },
        {id:4, title: 'Writing a simple component for A-Frame', url: 'https://www.linkedin.com/pulse/writing-simple-component-a-frame-kewal-kishan-gokuldas-1d/?trackingId=qa%2BkhFrXS%2Fazp0JrorDDxA%3D%3D', topics : [ 'Web-VR', 'AFrame', 'Component' ] , img : aframeImg },
        {id:5, title: 'AR Portal', url: 'https://www.linkedin.com/posts/kewalkishang_augmentedreality-business-digitalmarketing-activity-6510534676308955136-y6Sb?utm_source=share&utm_medium=member_desktop' , topics : [ 'Virtual-Portals', 'Advertising', 'ARCore' ] , img : portalImg },
        {id:6, title: 'ArVa', url: 'https://www.linkedin.com/posts/kewalkishang_augmentedreality-chatbots-artificialintelligence-activity-6487343493994897409-T_Zp/?utm_source=share&utm_medium=member_desktop', topics : ['Virtual Assistant', 'DialogFlow', 'ARCore' ], img : arvaImg },
      ];
    
      return (
        <div className={styles.projectContainer} ref={ref}>
        <div className="main-title" style={{paddingTop: "25px", fontWeight : "bold", fontSize : "32px"}}>
        <img src={skillsImg} alt="Posts" style={{height: "25px", margin : "10px"}} />
            Posts
        </div>
        <div style={{paddingLeft : "20px", paddingRight : "20px"}}>
          <Slider {...settings}>
               {posts.map((post) => (
                <div className={styles.slide} key={post.id}>
                    <PostItem key={post.id} post={post}></PostItem>
                </div>
               ))}
          </Slider>
        </div>
        </div>
      );

});

export default Posts;