// import darkImg from '../assets/images/moon-solid.svg';
import React, { useState, useEffect } from 'react';
import closeBtn from '../assets/images/close.png'
import collapsedBtn from '../assets/images/collapsed.png'

function Nav({ scrollToSection, refs }) {

    const [activeItem, setActiveItem] = useState("aboutMe");
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    // Add a scroll event listener to handle active items
    useEffect(() => {
        const handleScroll = () => {
            const offset = 0;
            
            for (const sectionName in refs) {
                const ref = refs[sectionName];
                if (ref && ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    if (rect.top - offset <= 0 && rect.bottom >= 0 - offset) {
                        setActiveItem(sectionName);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refs]);
    
    
    const handleItemClick = (sectionName, ref) => {
        // Set the clicked item as active
        setActiveItem(sectionName);
        // Scroll to the section
        scrollToSection(ref);
        setIsNavCollapsed(true); 
    };


    const handleNavbarToggle = () => {
      setIsNavCollapsed(!isNavCollapsed);
    };

    return (        
      <nav className="navbar navbar-inverse topnav bg-dark" style={{position: "fixed",
        top: "0",
        left: "0",
        width : "100%",
        // color : "#092635",
        zIndex: 2}}   id="myTopnav">
      <div className="container-fluid">
          <div className="navbar-header">
              {isNavCollapsed ? 
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar" onClick={handleNavbarToggle}>
                  {/* <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>                         */}
                   <img alt="uni" src={collapsedBtn} width={"25px"}></img>
              </button>:
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar" onClick={handleNavbarToggle}>
              {/* <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>                         */}
              <img alt="uni" src={closeBtn} width={"25px"}></img>
             </button> 
            }
              <a className="navbar-brand active" style={{ color : "white"}} href="#">Kewal Kishan Gokuldas</a>
          </div>
          <div className={`navbar-collapse ${isNavCollapsed ? 'collapse' : ''}`} id="myNavbar">
              <ul className="nav navbar-nav navbar-right">
                  <li className={activeItem === 'aboutMe' ? 'active' : ''}><a rel="noreferrer noopener" onClick={() => handleItemClick('aboutMe', refs.aboutMe)} href="javascript:void(0)">About me</a></li>    
                  <li className={activeItem === 'education' ? 'active' : ''}><a rel="noreferrer noopener" onClick={() => handleItemClick('education', refs.education)} href="javascript:void(0)">Education</a></li>
                  <li className={activeItem === 'experience' ? 'active' : ''}><a rel="noreferrer noopener" onClick={() => handleItemClick('experience', refs.experience)} href="javascript:void(0)">Experience</a></li>
                  <li className={activeItem === 'skills' ? 'active' : ''}><a rel="noreferrer noopener" onClick={() => handleItemClick('skills', refs.skills)} href="javascript:void(0)">Skills</a></li>
                  <li className={activeItem === 'projects' ? 'active' : ''}><a rel="noreferrer noopener" onClick={() => handleItemClick('projects', refs.projects)} href="javascript:void(0)">Projects</a></li>
                  <li className={activeItem === 'posts' ? 'active' : ''}><a rel="noreferrer noopener" onClick={() => handleItemClick('posts',refs.posts)} href="javascript:void(0)">Posts</a></li>
                 
                  {/* <li className={activeItem === 'socials' ? 'active' : ''}><a onClick={() => handleItemClick('socials',refs.socials)}>Let's Connect</a></li> */}
                  <li className={activeItem === 'resume' ? 'active' : ''}><a href="https://drive.google.com/drive/folders/1MpAVPtaw_X_t6h8lAocT66gb-Mdmg2EH?usp=share_link" target="_blank" >Resume</a></li>
              </ul>
          </div>
      </div>
  </nav>
    );
}
export default Nav;