import React from 'react';
import JavaImg from '../../assets/images/java.png';
import CImg from '../../assets/images/c++.png';
import PythonImg from '../../assets/images/python.png';
import SwiftImg from '../../assets/images/swift.png';

import HtmlImg from '../../assets/images/html.png';
import CssImg from '../../assets/images/css.png';
import JavascriptImg from '../../assets/images/javascript.svg';
import AngularImg from '../../assets/images/angular.png';
import NodeImg from '../../assets/images/node.png';
import ThreeImg from '../../assets/images/threejs.png';
// import SocketImg from '../../assets/images/websocket.png';
import JenkinsImg from '../../assets/images/jenkins.png';


import MysqlImg from '../../assets/images/mysql.png';
import MongodbImg from '../../assets/images/mongodb.svg';

import UnityImg from '../../assets/images/unity.png';
import AwsImg from '../../assets/images/aws.png';
import FirebaseImg from '../../assets/images/firebase.png';
import DockerImg from '../../assets/images/docker.png';

import AndroidImg from '../../assets/images/android.png';
import IosImg from '../../assets/images/ios.png';
import ReactImg from '../../assets/images/react.png';

import OpenCVImg from '../../assets/images/opencv.png';
import GitImg from '../../assets/images/git.png';
// import JiraImg from '../../assets/images/jira.png';
import TensorflowImg from '../../assets/images/tensorflow.png';
import skillsImg from '../../assets/images/integrity.png';
import styles from './Skills.module.css';


const Skills =  React.forwardRef((props, ref) => ( 

        <div className="skills-section wrapper" style={{ color: "white",
        width: "90%",
        margin: "4% 5%",
        borderRadius: "6px",
        // boxShadow: "0 0 4px 4px rgba(0,0,0,0.3)",
        paddingBottom: "50px"}} ref={ref}>
            <div className="skills-title" style={{paddingTop: "25px", paddingBottom: "25px"}}>
                 <div className="main-title" style={{fontWeight : "bold", fontSize : "32px"}}>  <img src={skillsImg} alt="C++" style={{height: "25px", margin : "10px"}} />Skills</div>
            </div>
            <div className="computer">
            <div className={styles.skilltype} > <b>Languages : </b>
            <div className={styles.computerskills}> 
               
                <div className={styles.skillImg}> <img src={JavaImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>  Java </div> </div>
                <div className={styles.skillImg}> <img src={PythonImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>  Python </div> </div>
                <div className={styles.skillImg}> <img src={CImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>  C++ </div></div>
                <div className={styles.skillImg}> <img src={SwiftImg} alt="Swift" style={{width: "50px"}} />
                <div className={styles.skillText}>Swift</div> </div>
            </div>
            </div>
            <div className={styles.skilltype} ><b> Mobile : </b> 
            <div className={styles.computerskills}> 
                
                <div className={styles.skillImg}> <img src={AndroidImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> Android </div></div>
                <div className={styles.skillImg}>  <img src={ReactImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>React Native</div></div>
                <div className={styles.skillImg}> <img src={IosImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>iOS</div> </div>
            
            </div>
            </div>
            <div className={styles.skilltype} > <b>DataBases :</b> 
            <div className={styles.computerskills}> 
               
                <div className={styles.skillImg}><img src={MysqlImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> MySQL </div></div>
                <div className={styles.skillImg}> <img src={MongodbImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>MongoDB </div></div>
            </div>
            </div>
            <div className={styles.skilltype} > <b>Platforms : </b> 
            <div className={styles.computerskills}> 
               
                <div className={styles.skillImg}> <img src={UnityImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>Unity3D</div> </div>
                <div className={styles.skillImg}> <img src={AwsImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> AWS </div> </div>
                <div className={styles.skillImg}><img src={FirebaseImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> Firebase </div> </div>
            </div>
            </div>
            <div className={styles.skilltype} > <b> Tools : </b>
            <div className={styles.computerskills}> 
             
                <div className={styles.skillImg}><img src={GitImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> GIT  </div></div>
                {/* <div className={styles.skillImg}> <img src={JiraImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>JIRA  </div></div> */}
                <div className={styles.skillImg}> <img src={TensorflowImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>Tensorflow </div> </div>
                <div className={styles.skillImg}> <img src={OpenCVImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>OpenCV </div> </div>
                <div className={styles.skillImg}> <img src={DockerImg} alt="Docker" style={{width: "50px"}} />
                <div className={styles.skillText}> Docker </div> </div>
            </div>
            </div>
          </div>
        
         <div style={{fontSize: "20px" , margin : "10px"}}><b> Web : </b>   </div>
          <div>
              <div className={styles.computerskillsweb} > 
                 <div className={styles.computerskillswebchild} >
                <div className={styles.skillImg}> <img src={HtmlImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> HTML </div></div>
                <div className={styles.skillImg}> <img src={CssImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}>CSS </div> </div>
                <div className={styles.skillImg}><img src={JavascriptImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> JavaScript </div> </div>

                <div className={styles.skillImg}><img src={ThreeImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> Three.js </div></div>
                </div> 
                <div className={styles.computerskillswebchild}>
                <div className={styles.skillImg}><img src={ReactImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> React </div></div>
                <div className={styles.skillImg}> <img src={AngularImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> Angular </div></div>
                <div className={styles.skillImg}> <img src={NodeImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> Node.js </div> </div>
                <div className={styles.skillImg}> <img src={JenkinsImg} alt="C++" style={{width: "50px"}} />
                <div className={styles.skillText}> Jenkins </div> </div>
                </div>
                </div>
          </div>
         

   
        </div>
    ));



export default Skills;