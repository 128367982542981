import React from 'react';
import skillsImg from '../../assets/images/chemistry.png';
import cmtImg from '../../assets/images/cmt.png';
import ctsImg from '../../assets/images/cts.png'
import absentiaImg from '../../assets/images/absentia.png'
import mimykImg from '../../assets/images/mimyk.png'
import styles from './Experience.module.css';


const Experience = React.forwardRef((props, ref) => (  
        <div className="experience-section wrapper" style={{ color: "black",
        width: "90%",
        margin: "4% 5%",
        borderRadius: "6px",
        // boxShadow: "0 0 5px 5px rgba(0,0,0,0.3)",
        paddingBottom: "50px"}} ref={ref} >
 <div className="experience-title"  style={{paddingTop: "25px", paddingBottom: "25px"}}>
         <div className="main-title" style={{fontWeight : "bold", fontSize : "32px", color:'white'}}> <img src={skillsImg} alt="C++" style={{height: "25px", margin : "10px"}} />Experience</div>
   </div>

 <div className="timeline">
 <div className="containera left">
    <div className="timeline-content">
    
      <div className={styles.comDetails}>
        <div>
        <img alt="uni" className={styles.comImg} src={cmtImg} />
        </div>
        <div>
      <div className="timeline-title"> Cambridge Mobile Telematics</div>
      <div className="timeline-sem">June 2021 - August 2022 </div>
      <div className="timeline-role">Software Engineer II </div>
      </div>
      </div>
      <hr></hr>
      <div className="timeline-desc">
        <ul>
        <li>
          Implemented new onboarding flow and authorization to induct all new users on the DriveScape apps.
        </li>
        <li>
        Optimized reverse geocoding computation on app for faster screen loads by 20%.
        </li>
        <li>
        Implemented and improved browse and video request features from 0 to 1 on mobile iteratively using agile methodology based on user feedbacks.
        </li>
        <li>
        Devised extensive unit tests to manage API routes and failure, enhancing application robustness by 25%.
        </li>
        <li>
        Refactored apps to facilitate switch from the Bluetooth hardware to LTE, reducing code base by 20% and app size by 66%.
        </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="containera right">
  <div className="timeline-content">
  <div className={styles.comDetails}>
        <div>
        <img alt="uni" className={styles.comImg} src={ctsImg} />
        </div>
        <div>
      <div className="timeline-title"> Cognizant</div>
      <div className="timeline-sem">Dec 2017 - Jun 2021 </div>
      <div className="timeline-role">Associate Developer </div>
      </div>
      </div>
      <hr></hr>
      <div className="timeline-desc">
        <ul>
        <li>
        Led immersive experience team, driving design and development of web-based augmented/virtual reality POCs and MVPs from 0
to 1 using JavaScript frameworks. Integrated real world detection using YOLO model for personalized experience        </li>
        <li>
        Reduced experience creation time for Android mobile apps by 60% by developing a user-friendly web Content Management
System deploying MERN stack, enabling non-technical individuals to create experiences using a no-code platform </li>
        <li>
        Designed an Indoor navigation mobile app utilizing SLAM and Image markers, utilizing BFS for shortest-path routing for users        </li>
        <li>
        Developed the frontend for an immersive chatbot experience using Vue.js and WebXR for a leading mobile company, enhancing
customer engagement and interaction by 20%        </li>
<li>
Designed and created the backend for a web shopping platform utilizing React and Flask framework, leveraging AR/VR
frameworks to create an in-store shopping experience, resulting in a notable 20% increase in conversion rates
</li>

<li>
Conducted demonstrations and delivered presentations on immersive solutions, such as WebXR Magazines, to communicate
potential applications of AR/VR in various domains to customers and leadership

</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="containera left">
    <div className="timeline-content">
    <div className={styles.comDetails}>
        <div>
        <img alt="uni" className={styles.comImg} src={absentiaImg} />
        </div>
        <div>
      <div className="timeline-title"> Absentia VR</div>
      <div className="timeline-sem">July 2017 - Dec 2017 </div>
      <div className="timeline-role">Intern </div>
      </div>
      </div>
      <hr></hr>
      <div className="timeline-desc">
        <ul>
        <li>
        Created AR/VR experiences on mobile for clients for their customer engagement programs.
        </li>
        <li>
        Optimized older VR games to reduce VR sickness for a smoother experience, reducing user attrition by 50%.
        </li>
        <li>
        Tested and validated packages for various features in sniper game, reducing development time.
        </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="containera right">
  <div className="timeline-content">
  <div className={styles.comDetails}>
        <div>
        <img alt="uni" className={styles.comImg} src={mimykImg} />
        </div>
        <div>
      <div className="timeline-title"> MIMYK </div>
      <div className="timeline-sem">Feb 2017 - April 2021 </div>
      <div className="timeline-role">Graphics Intern </div>
      </div>
      </div>
      <hr></hr>
      <div className="timeline-desc">
        <ul>
        <li>
          Gamified the learning framework of the endoscopy simulator for an engaging experience.
        </li>
        <li>
         Created realistic shader effects to make simulation look more authentic and appealing to users.
        </li>
        <li>
          Wrote plugins to integrate haptics hardware with the simulation.
        </li>
        </ul>
      </div>
    </div>
    </div>
</div>
        </div>
));



export default Experience;